import React, { useEffect, useState } from "react";
import { Button, Card, TextField } from "@material-ui/core";
import { makeStyles, styled } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import adminServices from "../../utils/adminServices";
import { toast } from "react-toastify";

import CustomTable from "../../component/CustomTable";
import { API_BASE_URL } from "../../sidebar/Url";
import { useLocation, useNavigate } from "react-router-dom";


function rand() {
  return Math.round(Math.random() * 20) - 10
}

function getModalStyle() {
  const top = 50 + rand()
  const left = 50 + rand()

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    marginTop: -20,
    marginLeft: 70,
    width: 400,
    height: 250,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))


const Category = () => {
  const [modalStyle] = React.useState(getModalStyle);
  const [openEdit, setOpenEdit] = useState(false);
  const [name, setName] = useState('');
  const [data, setData] = useState('');
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [reload, setReload] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [category, setCategory] = useState("");

  const [editCategory, setEditCategory] = useState();


  const Input = styled("input")({
    display: "none",
  });

  useEffect(() => {
    async function getCategory() {
      setLoading(true);
      const res = await adminServices.getCategory(0);
      setLoading(false);

      if (res.status) {
        setData(res.data);
      }


    }
    getCategory()
  }, [reload])
  const uploadImage = async () => {
    const formData = new FormData();
    formData.append("image", selectedImage);
    setLoading(true);
    let res = await adminServices.addIcon(category._id, formData);
    setLoading(false);
  };




  let columns = [
    {
      title: "Name",
      index: "name",

    },
    {
      title: 'Icon',
      render: (item) => {

        return (
          <div>
            <img
              width="90px"
              height="90px"
              alt="logo"
              src={API_BASE_URL + "/" + item.icon}
            ></img>

          </div>
        )
      },
    },
    {
      title: 'Action',
      render: (item) => {
        return (
          <div >

            <Button color="primary" variant="contained" style={{ margin: "10px" }} onClick={() => handleOpen(item)}>Edit</Button>
            <Button color="primary" variant="contained" onClick={() => {
              setCategory(item)
              setOpenEdit(true)
            }} >Add Icon</Button>
          </div>
        )
      },
    },

  ];




  const addhandleClick = async (e) => {
    let res;
    if (editCategory) {
      res = await adminServices.updateCategory({ name: name }, editCategory._id);
    } else {
      res = await adminServices.addcategory({ name: name });
    }

    if (res.status) {
      setOpen(false);
      setCategory(res.data.data);
      setReload(reload + 1);
      setEditCategory();
      setName("");
      toast("successfully created category");
    } else {
      toast("unable to create the category");
    }
  }

  const handleOpen = (item) => {
    setEditCategory(item);
    setName(item?.name);
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setEditCategory();
    setName("");
    setReload(reload + 1);

  }
  const handleEditClose = () => {
    setOpenEdit(false)
    setSelectedImage("");
  }

  const body = (
    <div style={modalStyle
    } className={classes.paper}>
      <h2 id="modal-title">{editCategory ? "Edit Category" : "Add Category"}</h2>

      <div>
        <TextField
          style={{ marginTop: "5%" }}
          id="modal-description"
          label="name"
          fullWidth
          autoFocus
          defaultValue=""
          variant='outlined'
          size="small"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: "10%" }}>
        <Button color="primary" variant="contained" onClick={addhandleClick}>
          {editCategory ? "Edit" : "Add"}
        </Button>
        <Button color="primary" variant="contained" onClick={handleClose}>
          close
        </Button>
      </div>
    </div>
  )


  const handleImage = (
    <div style={modalStyle
    } className={classes.paper}>
      <label htmlFor="contained-button-file">
        <img
          alt="logo"
          className="upload_img"
          src={selectedImage && URL.createObjectURL(selectedImage)}
        ></img>

        <Input

          onChange={(event) => {
            setSelectedImage(event.target.files[0]);
          }}
          accept="image/*"
          id="contained-button-file"
          type="file"
        />

      </label>
      <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: "10%" }}>
        <Button color="primary" variant="contained" onClick={() => uploadImage()}>
          Add
        </Button>
        <Button color="primary" variant="contained" onClick={handleEditClose}>
          close
        </Button>
      </div>
    </div>
  )



  return <Card>

    <CustomTable
      title={'Category'}
      loading={loading}
      columns={columns}
      data={data}
      actions={
        <div>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleOpen()}
          >
            Add Category
          </Button>

        </div>

      }

    />

    <Modal
      open={open}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"

    >
      {body}
    </Modal>

    <Modal
      open={openEdit}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"

    >
      {handleImage}
    </Modal>


  </Card>

}
export default Category;