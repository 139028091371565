import React from "react";
import { Card, TextField, Grid, Button } from "@material-ui/core";

const CreateVendor = () => {
  return (
    <Card>
      <div className="vendor_create">
        <h3>Create Vendor</h3>
      </div>
      <div className="vendor_details">
        <Grid container spacing={9}>
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label={"Name"}
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label={"Email"}
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label={"Password"}
              variant="outlined"
              size="small"
            />
          </Grid>
        </Grid>
        <Grid container spacing={9}>
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label={"Contact Number"}
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label={"Fashion Type"}
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label={"Service Type"}
              variant="outlined"
              size="small"
            />
          </Grid>
        </Grid>
        <Grid container spacing={9}>
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label={"Service Category"}
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label={"Price"}
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label={""}
              variant="outlined"
              size="small"
            />
          </Grid>
        </Grid>
      </div>
      <div className="btn_sub">
        <Button color="primary" variant="contained">
          Submit
        </Button>
      </div>
    </Card>
  );
};
export default CreateVendor;
