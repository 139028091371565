// import React, { useState } from "react";
// import {
//   Avatar,
//   Box,
//   Button,
//   Card,
//   Drawer,
//   Link,
//   Popper,
//   TextField,
// } from "@material-ui/core";
// import { vendorSidebar } from "../sidebar/Sidebar";
// import { NavLink } from "react-router-dom";
// import FormatIndentIncreaseIcon from "@material-ui/icons/FormatIndentIncrease";
// import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router";
// import FaceIcon from "@material-ui/icons/Face";
// const Navbar = (props) => {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const [click, setClick] = useState(false);
//   const handelIcon = () => {
//     setClick(!click);
//   };
//   const handleClick = () => {
//     setClick(!click);
//   };
//   const Logout = () => {
//     localStorage.removeItem("user");
//     navigate("/login");
//     dispatch({ type: "ACTION_LOGOUT", data: "" });
//   };
//   const [profile, setProfile] = useState(false);
//   const ProfileClick = () => {
//     setProfile(!profile);
//   };
//   return (
//     <>
//       <Card className="card">
//         <div
//           style={{ pointer: "cursor", zIndex: "100" }}
//           className="icon-invis-vis"
//           onClick={handelIcon}
//         >
//           <FormatIndentIncreaseIcon />
//         </div>
//         <div>
//           <Avatar className="profile_icon">
//             <i class="fas fa-user" onClick={ProfileClick}></i>
//           </Avatar>
//         </div>
//         <div className="nav">
//           <h2 className="header-invis">Hairdo</h2>
//           <div className="nav_bar">
//             {vendorSidebar.map((v) => {
//               return (
//                 <NavLink to={v.to} className="nav_menu">
//                   <i class={v.icon}></i>
//                   <span style={{ padding: 10 }}> {v.title}</span>
//                 </NavLink>
//               );
//             })}
//           </div>
//         </div>
//         {click && (
//           <div className="nav1" onClick={handleClick}>
//             <center>
//               <h2 className="header-invis1">Hairdo</h2>
//             </center>
//             <div className="nav_bar1">
//               {vendorSidebar.map((v) => {
//                 return (
//                   <NavLink to={v.to} className="nav_menu">
//                     <i class={v.icon}></i>
//                     <span style={{ padding: 10, color: "black" }}> {v.title}</span>
//                   </NavLink>
//                 );
//               })}
//             </div>
//           </div>
//         )}
//       </Card>
//       <div>
//         {profile && (
//           <div className="profilecard_dropdown">
//             <ul>
//               <li
//                 style={{ cursor: 'pointer' }}
//                 onClick={() => {
//                   setProfile(false);
//                   navigate('/updateprofile')
//                 }
//                 }
//               >
//                 Update Profile
//               </li>
//               <hr />
//               <li
//                 style={{ cursor: 'pointer' }}
//                 onClick={() => {
//                   setProfile(false);
//                   navigate('/resetpassword')
//                 }
//                 }
//               >
//                 Reset Password
//               </li>
//               <hr />
//               <li>
//                 <button style={{ cursor: "pointer" }} onClick={Logout}>
//                   Logout
//                 </button>
//               </li>
//             </ul>
//           </div>
//         )}
//       </div>
//     </>
//   );
// };
// export default Navbar;
import React, { useState } from 'react'
import {
  Avatar,
  Box,
  Button,
  Card,
  Drawer,
  Link,
  Popper,
  TextField,
} from '@material-ui/core'
import { vendorSidebar } from '../sidebar/Sidebar'
import { NavLink } from 'react-router-dom'
import FormatIndentIncreaseIcon from '@material-ui/icons/FormatIndentIncrease'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import FaceIcon from '@material-ui/icons/Face'
const Navbar = (props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [click, setClick] = useState(false)
  const handelIcon = () => {
    setClick(!click)
  }
  const handleClick = () => {
    setClick(!click)
  }
  const Logout = () => {
    localStorage.removeItem('user')
    navigate('/login')
    dispatch({ type: 'ACTION_LOGOUT', data: '' })
  }
  const [profile, setProfile] = useState(false)
  const ProfileClick = () => {
    setProfile(!profile)
  }
  return (
    <>
      <Card className="card">
        <div
          style={{ pointer: 'cursor', zIndex: '100', paddingLeft: "15px" }}
          className="icon-invis-vis"
          onClick={handelIcon}
        >
          <FormatIndentIncreaseIcon />
        </div>
        {click && (
          <div onClick={handleClick}>
            <center>
              <h2 className="header-invis1">Hairdo</h2>
            </center>
            <div className="nav_bar1">
              {vendorSidebar.map((v) => {
                return (
                  <NavLink to={v.to} className="nav_menu">
                    <i class={v.icon}></i>
                    <span style={{ padding: 10 }}> {v.title}</span>
                  </NavLink>
                )
              })}
            </div>
          </div>
        )}



        <div>
          <Avatar className="profile_icon">
            <i class="fas fa-user" onClick={ProfileClick}></i>
          </Avatar>
        </div>
        <div className="nav">
          <h2 className="header-invis">Hairdo</h2>
          <div className="nav_bar">
            {vendorSidebar.map((v) => {
              return (
                <NavLink to={v.to} className="nav_menu">
                  <i class={v.icon}></i>
                  <span style={{ padding: 10 }} > {v.title}</span>
                </NavLink>
              )
            })}
          </div>
        </div>
        {/* {click && (
          <div className="nav1" onClick={handleClick}>
            <center>
              <h2 className="header-invis1">Hairdo</h2>
            </center>
            <div className="nav_bar1">
              {vendorSidebar.map((v) => {
                return (
                  <NavLink to={v.to} className="nav_menu">
                    <i class={v.icon}></i>
                    <span style={{ padding: 10 }}> {v.title}</span>
                  </NavLink>
                )
              })}
            </div>
          </div>
        )} */}

      </Card>
      <div>
        {profile && (
          <div className="profilecard_dropdown">
            <ul>
              <li
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate('/updateprofile')
                  setProfile(false);
                }
                }
              >
                Update Profile
              </li>
              <li
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate('/resetpassword');
                  setProfile(false);
                }}
              >
                Reset Password
              </li>
              <li>
                <button style={{ cursor: 'pointer' }} onClick={Logout}>
                  Logout
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
    </>
  )
}
export default Navbar
