import React, { useState, useEffect } from 'react'
import adminServices from '../../utils/adminServices'
import { Card, Button } from '@material-ui/core'
import CustomTable from '../../component/CustomTable'
import { useNavigate } from 'react-router'
const Users = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      const res = await adminServices.getusers();
      setLoading(false);

      if (res.data && res?.status === true) {
        setData(res.data)
      }
    }
    fetchData()
  }, [0])
  let columns = [
    {
      title: 'NAME',
      index: 'name',
    },
    {
      title: 'EMAIL',
      index: 'email',
    },
    {
      title: 'NUMBER',
      index: 'mobile',
    },
    {
      title: 'TYPE',
      index: 'type',
    },

    {
      title: 'Action',
      index: 'status',
      variant: 'contained',

      render: (item) => {
        return (
          <div>
            <Button
              onClick={() => navigate('/users/' + item._id, { state: item })}
              variant="contained"
              size="small"
              color="primary"
              style={{ margin: '10px 0px 0px 10px' }}
            >
              View
            </Button>

          </div>
        )
      },
    },
  ]

  return (
    <Card>
      <div>
        <CustomTable
          title={'Users List'}
          loading={loading}
          columns={columns}
          data={data}
        />
      </div>
    </Card>
  )
}

export default Users
