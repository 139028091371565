import axios from 'axios';
import { API_BASE_URL } from '../sidebar/Url';

export const instance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 500000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});


instance.interceptors.request.use(function (config) {
  let user = localStorage.getItem("user");
  let userObj;
  if (user) {
    userObj = JSON.parse(user);

  }

  return {
    ...config,
    headers: {
      access_token: userObj ? userObj.access_token : null

    },
  };
});

const responseBody = (response) => response.data;

const handleError = (error) => {
  if (error.response) {
    return error.response.data
  } else if (error.request) {
    return { status: false, message: 'Invalid request' };
  }
  return { status: false, message: 'Some error occured, please try again' };
}


const requests = {
  get: (url, body, headers) =>
    instance.get(url, body, headers).then(responseBody).catch(handleError),

  post: (url, body) => instance.post(url, body).then(responseBody).catch(handleError),

  put: (url, body, headers) =>
    instance.put(url, body, headers).then(responseBody).catch(handleError),

  patch: (url, body) => instance.patch(url, body).then(responseBody).catch(handleError),

  delete: (url) => instance.delete(url).then(responseBody).catch(handleError),
};

export default requests;