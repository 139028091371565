import requests from "./httpServices";

const adminServices = {
  adminLogin(body) {
    return requests.post(`/auth/admin-login`, body);
  },
  getVendors() {
    return requests.get(`/admin/vendors`);
  },
  getusers() {
    return requests.get(`/admin/users`);
  },
  getConfig() {
    return requests.get(`/admin/config`);
  },
  vendor(body) {
    return requests.post(`admin/vendor-status`, body);
  },
  creatUser(body) {
    return requests.post(`/admin/user`, body);
  },
  adminUser() {
    return requests.get(`/admin/user`);
  },
  addcategory(body) {
    return requests.post(`/admin/category`, body);
  },
  updateCategory(body, id) {
    return requests.post(`/admin/category/${id}`, body);
  },
  resetpassword(body) {
    return requests.post(`/admin/reset-password`, body);
  },
  forgotpassword(body) {
    return requests.post(`/auth/forgot-password`, body)
  },
  changepassword(body) {
    return requests.post(`/auth/change-password`, body)
  },
  getDashboard() {
    return requests.get(`/admin/dashboard`);
  },

  updateConfig(body) {
    return requests.post('/admin/config-bulk', body);
  },
  getCategory(id) {
    return requests.get(`/public/category/${id}`);
  },
  addIcon(id, formData) {
    return requests.post(`/admin/category-icon/${id}`, formData);
  }

}

export default adminServices;
