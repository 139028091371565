import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Mainapp from './Mainapp'
import { ToastContainer } from './utils/toast'
function App() {
  return (
    <div className="App">
      <ToastContainer />
      <BrowserRouter>
        <Mainapp />
      </BrowserRouter>
    </div>
  )
}
export default App
