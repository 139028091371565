import * as React from 'react'
import {
  Table,
  Typography,
  TableContainer,
  TextField,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  CircularProgress,
} from '@material-ui/core'

export default function CustomTable({
  columns = [],
  title,
  data = [],
  loading,
  onSearch,
  actions,
}) {
  const [searchText, setSearchText] = React.useState('')

  if (loading) {
    return <CircularProgress />
  }

  function getProperty(json, path) {
    var tokens = path.split('.')
    var obj = json
    for (var i = 0; i < tokens.length; i++) {
      if (obj) {
        obj = obj[tokens[i]]
      }
    }
    return obj
  }

  return (
    <div className="border">
      <div
        style={{
          padding: '5px',
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div >
          <Typography variant="h5" className="sub-Heading" >
            {title}
          </Typography>
        </div>
        <div>{actions != undefined ? actions : null}</div>
      </div>
      {data.length === 0 ? (
        <p>No data found</p>
      ) : (
        <TableContainer>
          {onSearch && (
            
            <div style={{ margin: '10px' }}>
              <TextField
                className="Table-root-one"
                onChange={(event) => {
                  setSearchText(event.target.value)
                }}
                value={searchText}
                style={{ padding: '10px' }}
                placeholder="Search"
              />
              <Button onClick={() => onSearch(searchText)} variant="contained">
                Search
              </Button>
            </div>
          )}

          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className="border-one"
          >
            <TableHead className="table_heading_clr">
              <TableRow className="Table-header">
                {columns.map((d) => {
                  return (
                    <TableCell className="table_title_clr">{d.title}</TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody className="row_hover">
              {data.map((row, i) => (
               
                <TableRow
                  key={i}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
              
                  {columns.map((d) => {
                    if (d.render) {
                      return (
                        <TableCell component="th" scope="row">
                          {d.render(row)}
                        </TableCell>
                      )
                    }
                    if (d.action) {
                      return (
                        <TableCell component="th" scope="row" >
                          <Button
                        
                            variant={d.variant}
                            onClick={() => d.onClick(row)}
                          >
                            {d.action}
                          </Button>
                        </TableCell>
                      )
                    }

                    return (
                      <TableCell component="th" scope="row">
                        {getProperty(row, d.index)}
                      </TableCell>
                    )
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  )
}
