import { Button, Grid, TextField, Paper } from '@material-ui/core'
import React, { useState } from 'react'
import adminServices from '../utils/adminServices'
import { ACTION_LOGIN } from '../store/actions'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { Link } from '@material-ui/core'
import { notifyError, notifySuccess } from '../utils/toast'
import { toast } from 'react-toastify'

const Login = () => {
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [loading, setLoading] = useState(false)
  const [formError, setFormError] = useState("");

  const navigate = useNavigate()

  const handleClick = async (e) => {
    e.preventDefault()
    if (email === '') {
      setEmailError('email required')
    }
    if (password === '') {
      setPasswordError('password required')
    }
    setFormError('')
    if (emailError || passwordError) {
      return
    }
    setLoading(true)
    const res = await adminServices.adminLogin({
      email: email,
      password: password,
    })
    setLoading(false)

    console.log(res);

    if (res.status) {
      localStorage.setItem('user', JSON.stringify(res.data))
      dispatch({ type: ACTION_LOGIN, data: res.data })
      notifySuccess(res.message)
    } else {
      setEmail('');
      setPassword('');
      setFormError(res.message);
    }

  }
  return (
    <Grid style={{ padding: '15px' }}>
      <Paper elevation={3} className="box_style">
        <Grid align="center">
          <h2>Welcome Back</h2>
          <h2>HairDo</h2>
        </Grid>

        <div className="input_style">
          <TextField
            autoFocus
            label="email"
            size="small"
            error={emailError}
            value={email}
            variant="outlined"
            onChange={(event) => {
              setEmailError('')
              setEmail(event.target.value)
            }}
            helperText={emailError}
          />
        </div>
        <div className="input_styles">
          <TextField
            label="password"
            error={passwordError}
            variant="outlined"
            size="small"
            value={password}
            onChange={(event) => {
              setPasswordError('')
              setPassword(event.target.value)
            }}
            helperText={passwordError}
          />
        </div>

        <div className="login_button">
          <Button color="primary" variant="contained" onClick={handleClick}>
            Login
          </Button>

        </div>
        <p style={{ color: 'red' }}>{formError}</p>
        <div className="forgot_text">
          <Link
            href="/forgot"
            color="inherit"
            style={{ textDecoration: 'none' }}
          >
            Forgot Password?
          </Link>
        </div>
      </Paper>
    </Grid>
  )
}

export default Login
