import React, { useState } from 'react';
import { Grid, Paper, Button, TextField } from '@material-ui/core';
import { Link } from '@material-ui/core';
import adminServices from '../utils/adminServices';
import { useNavigate } from 'react-router';
import { notifySuccess } from '../utils/toast';



const Forgotpassword = () => {
  const navigate=useNavigate();
  const [email,setEmail]=useState('');
  const [emailError,setEmailError]=useState('');
 const [verifycode,setVerifyCode]=useState('')
 const [password,setPassword]=useState('');
  const handleClick=async(e)=>{
    e.preventDefault(); 
    
    if(email===""){
      setEmailError('email required')
      }else{
      setEmailError('')
      }
      const res = await adminServices.forgotpassword({email:email});
    console.log("result",res)
    notifySuccess(res.message);

 
 

    navigate("/changepassword" )
    }

  return (
    <div>
     
      <Grid>  
      <Paper elevation={3} className="forgot_box">
     <Grid align="center">
        <h2>Fotgot password</h2>
        </Grid>
      <div className='input_style'>
        <TextField  
        label="email"
        margin="normal"
        size="small"
         placeholder="enter email"
         error={emailError}
         helperText={emailError}
        value={email} 
        variant="outlined"
        onChange={(event)=>setEmail(event.target.value)} />
         </div><br/><br/>
         <div className='recovery_button'>
     <Button variant="contained" type="submit" color="primary"   onClick={handleClick} >
    Submit
    </Button>
  
    </div>
    <div style={{marginTop:50}}>  
    <Link style={{padding:10,textDecoration: 'none'}} href="/login">Back to Login</Link>
    </div>
        </Paper> 
      </Grid>

    </div>
  )
}

export default Forgotpassword
