import React from 'react'
import Navbar from '../dashborad/Navbar'
import { Route, Routes, Navigate } from 'react-router-dom'
import Dashboard from '../dashborad/Dashboard'
import Vendors from '../dashborad/Vendors/Vendors'
import VendorDetails from '../dashborad/Vendors/VendorDetails'
import Users from '../dashborad/Users/Users'
import CreateVendor from '../dashborad/Vendors/CreateVendor'
import Configuration from '../dashborad/Configuration'
import UsersDetails from '../dashborad/Users/UsersDetails'
import AdminUsers from '../dashborad/AdminUsers/AdminUsers'
import CreateUser from '../dashborad/AdminUsers/CreateUser'

import Updateprofile from '../dashborad/Profile/Updateprofile'
import Resetpassword from '../login/Resetpassword'
import Category from '../dashborad/Category'


const VendorRoutes = () => {
  return (
    <div>
      <Navbar />
      <div className="dashboard">
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} exact />
          <Route path="/createVendor" element={<CreateVendor />} exact />
          <Route path="/vendors" element={<Vendors />} exact />
          <Route path="/vendors/:id" element={<VendorDetails />} exact />
          <Route path="/users" element={<Users />} exact />
          <Route path="/users/:id" element={<UsersDetails />} exact />
          <Route path="/configuration" element={<Configuration />} exact />
          <Route path="*" element={<Navigate to="/dashboard" replace />} />
          <Route path="/updateprofile" element={<Updateprofile />} exact />
          <Route path="/createuser" element={<CreateUser />} exact />

          <Route path="/adminusers" element={<AdminUsers />} exact />
          <Route path="/category" element={<Category />} exact />
          <Route path="/resetpassword" element={<Resetpassword />} exact />
        </Routes>
      </div>
    </div>
  )
}
export default VendorRoutes
