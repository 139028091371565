import { Button, Card, Grid, Paper, TextField } from '@material-ui/core'
import React from 'react'
const Updateprofile = () => {
  return (
    <Grid>
      <Paper elevation={3} className="updateprofile">
        <br/>
        <Grid align="center">
          <h2>Update Profile</h2>
        </Grid>
        <div className="update_input">
          <TextField
          autoFocus
          variant="outlined"
            label="name" 
            size="small" 
          />
        </div>
        <div className="update_input">
          <TextField
            label="email" 
            variant="outlined"
            size="small"
          />
        </div>
        <div className="update_input">
          <TextField
            label="contact number" 
            variant="outlined"
            size="small"
          />
        </div>
        <div className='update_button'>
          <Button variant="contained" color="primary">Update</Button>
        </div>
        </Paper>
        </Grid>
  )
}

export default Updateprofile
