import { Button, Grid, Paper, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import adminServices from '../utils/adminServices'
import { notifyError, notifySuccess } from '../utils/toast'

const Resetpassword = () => {
  const [currentpswd, setCurrentpswd] = useState()
  const [newpswd, setNewpswd] = useState()
  const changeClick = async (e) => {
    const res = await adminServices.resetpassword({
      password: currentpswd,
      new_password: newpswd,
    })
    if (res.status === true) {
      notifySuccess(res.message)
    } else {
      notifyError(res.message)
    }
  }
  return (
    <Grid>
      <Paper elevation={3} className="updateprofile">
        <br />
        <Grid align="center">
          <h2>Reset Password</h2>
        </Grid>
        <div className="update_input">
          <TextField
            variant="outlined"
            label="current password"
            size="small"
            onChange={(e) => setCurrentpswd(e.target.value)}
          />
        </div>
        <div className="update_input">
          <TextField
            variant="outlined"
            label="new password"
            size="small"
            onChange={(e) => setNewpswd(e.target.value)}
          />
        </div>
        <br />
        <div className="update_button">
          <Button variant="contained" color="primary" onClick={changeClick}>
            Change
          </Button>
        </div>
      </Paper>
    </Grid>
  )
}

export default Resetpassword
