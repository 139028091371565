import { Button, Grid, Paper, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import adminServices from '../utils/adminServices';
import { useLocation } from 'react-router';
import { notifyError, notifySuccess } from '../utils/toast';

const Changepassword = (res) => {
    let location = useLocation();
    let data=location.email;
  
const[email,setEmail]=useState('');
    const [verify,setVerify]=useState('');
    const[password,setpassword]=useState('');
const handleClick=async()=>{
const res=await adminServices. changepassword({email:email,verification_code:verify,password:password});
// notifySuccess(res.message)
if(res.status===true){
  notifySuccess(res.message)
 }else{
  notifyError(res.message)
 }
}
  return (
    <div>
      <Grid>  
      <Paper elevation={3} className="forgot_box">
     <Grid align="center">
        <h2>Change Password</h2>
        </Grid>
      <div className='input_style'>
      <TextField 
        label="email"
        margin="normal"
        size="small"
        value={res.email}
        variant="outlined"
        onChange={(event)=>setEmail(event.target.value)} 
   />
        <TextField 
        label="verification_code"
        margin="normal"
        size="small"
         placeholder="enter 6-digit otp"
        value={verify} 
        variant="outlined"
        onChange={(event)=>setVerify(event.target.value)} />

<TextField  
        label="password"
        margin="normal"
        size="small"
         placeholder="enter password"
        value={password} 
        variant="outlined"
        onChange={(event)=>setpassword(event.target.value)} />
         </div><br/><br/>
         <div className='recovery_button'>
     <Button variant="contained" type="submit" color="primary"   onClick={handleClick} >
   Change
    </Button>
  
    </div>
    
        </Paper> 
      </Grid>
     
    </div>
  )
}

export default Changepassword
