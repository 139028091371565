import React, { useState, useEffect } from 'react'
import adminServices from '../../utils/adminServices'
import { Card, Button, TextField, Grid, Typography } from '@material-ui/core'
import CustomTable from '../../component/CustomTable'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'

const Users = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState('');
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [type, setType] = useState('');
  const [password, setPassword] = useState('');
  const [click, setClick] = useState(true);
  const [nameerror, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [mobileError, setMobileError] = useState('');
  const [typeError, setTypeError] = useState('');
  const handleName = (event) => {
    if (name.length >= 0) {
      setNameError('');
    }
    setName(event.target.value)
  }
  const handleEmail = (event) => {
    if (email.length >= 0) {
      setEmailError('');
    }
    setEmail(event.target.value)
  }
  const handlePassword = (event) => {
    if (password.length >= 0) {
      setPasswordError('');
    }
    setPassword(event.target.value);
  }

  const handleMobile = (event) => {
    if (mobile.length >= 0) {
      setMobileError('');
    }
    setMobile(event.target.value)
  }
  const handleType = (event) => {
    if (type.length >= 0) {
      setTypeError('')
    }
    setType(event.target.value);
  }
  const handleClick = async (e) => {
    if (name === '') {
      setNameError('name required');
    }

    if (email === '') {
      setEmailError('email required');
    } else if (email.length >= 0) {
      setEmailError('');
    }
    if (password === '') {
      setPasswordError('password required');
    }
    if (mobile === '') {
      setMobileError('number required');
    }
    if (type === '') {
      setTypeError('type required');
    }
    const res = await adminServices.creatUser({
      name: name,
      email: email,
      mobile: mobile,
      password: password,
      type: type,
    })
    if (res.status) {
      toast("successfully created user");
      navigate("/adminusers");
    } else {
      toast("Unable to create the user");
    }
    if (res?.status === false) {
      setEmailError(res.message)
    } else {
      setEmailError('');
    }

  }

  return (

    <Card >

      <Typography variant="h5" style={{ margin: '30px' }} >User</Typography>

      <div style={{ margin: "5% 2% 5% 3%" }}>
        <Grid container
          spacing={{ xs: 3, md: 6 }}

          columns={{ xs: 3, sm: 3, md: 6 }}
        >

          <div style={{ padding: 20 }}>
            <Grid item xs={12} sm={12} md={12} >
              <TextField

                autoFocus
                label="Name"
                size="small"

                error={nameerror}
                value={name}
                variant="outlined"
                onChange={handleName}
                helperText={nameerror}


              />
            </Grid>
          </div>
          <div style={{ padding: 20 }}>
            <Grid item xs={12} sm={12} md={12} >
              <TextField
                autoFocus
                label="Email"
                size="small"

                error={emailError}
                value={email}
                variant="outlined"
                onChange={handleEmail}
                helperText={emailError}


              />
            </Grid>
          </div>
          <div style={{ padding: 20 }}>
            <Grid item xs={12} sm={12} md={12} >
              <TextField

                autoFocus
                label="Password"
                size="small"

                error={passwordError}
                value={password}
                variant="outlined"
                onChange={handlePassword}
                helperText={passwordError}


              />
            </Grid>
          </div>
          <div style={{ padding: 20 }}>
            <Grid item xs={12} sm={12} md={12} >
              <TextField

                id="outlined-basic"
                label={"Phone Number"}
                variant="outlined"
                size="small"
                error={mobileError}
                value={mobile}

                onChange={handleMobile}
                // onChange={(event) => setEmail(event.target.value)}
                helperText={mobileError}


              />
            </Grid>
          </div>
          <div style={{ padding: 20 }}>
            <Grid item xs={12} sm={12} md={12} >
              <TextField

                id="outlined-basic"
                label={"Type"}
                size="small"
                error={typeError}
                value={type}
                variant="outlined"
                onChange={handleType}
                // onChange={(event) => setEmail(event.target.value)}
                helperText={typeError}

              />
            </Grid>
          </div>

        </Grid>
        <Button
          onClick={handleClick}
          // value={close}
          color="primary"
          variant="contained"
          style={{ margin: "50px 10px 20px 20px" }}
        >
          Add User
        </Button>

        {/* <Grid container spacing={{ xs: 6, md: 3 }}
          columns={{ xs: 3, sm: 3, md: 3 }}
        >

          <Grid item xs={12} sm={3} md={4}>
            <TextField
              autoFocus
              label="Name"
              size="small"

              error={nameerror}
              value={name}
              variant="outlined"
              onChange={handleName}
              helperText={nameerror}

            />
          </Grid>
          <Grid item xs={12} sm={3} md={4}>
            <TextField
              autoFocus
              label="Email"
              size="small"

              error={emailError}
              value={email}
              variant="outlined"
              onChange={handleEmail}
              helperText={emailError}

            />
          </Grid>
          <Grid item xs={12} sm={3} md={4}>
            <TextField
              autoFocus
              label="Password"
              size="small"

              error={passwordError}
              value={password}
              variant="outlined"
              onChange={handlePassword}
              helperText={passwordError}

            />
          </Grid>


        </Grid>

        <div style={{ marginTop: "5%" }}>
          <Grid container spacing={{ xs: 6, md: 3 }}>
            <Grid item xs={12} sm={3} md={4}>
              <TextField
                id="outlined-basic"
                label={"Phone Number"}
                variant="outlined"
                size="small"
                error={mobileError}
                value={mobile}

                onChange={handleMobile}
                // onChange={(event) => setEmail(event.target.value)}
                helperText={mobileError}


              />
            </Grid>
            <Grid item xs={12} sm={3} md={4}>
              <TextField
                id="outlined-basic"
                label={"Type"}
                size="small"
                error={typeError}
                value={type}
                variant="outlined"
                onChange={handleType}
                // onChange={(event) => setEmail(event.target.value)}
                helperText={typeError}

              />
            </Grid>
          </Grid>
        </div>
        <Button
          onClick={handleClick}
          // value={close}
          color="primary"
          variant="contained"
          style={{ marginTop: "5%" }}
        >
          Add User
        </Button> */}
      </div>


    </Card>
  )
}

export default Users
