import React from "react";
import { useDispatch, useSelector } from "react-redux";
import LoginRoutes from "./routes/LoginRoutes";
import VendorRoutes from "./routes/VendorRoutes";
import { useEffect } from "react";
import { ACTION_LOGIN } from "./store/actions";


const Mainapp = () => {
    const loginStatus = useSelector(state => state.loginStatus);
    const dispatch = useDispatch();
    useEffect(() => {
        let user = localStorage.getItem("user");

        if (user) {
            dispatch({ type: ACTION_LOGIN, data: JSON.parse(user) })

        }
    }, [0]);
    
    if (!loginStatus) {
        return <LoginRoutes />

    } else {
        return <VendorRoutes />
    }

}
export default Mainapp;