import React, { useEffect, useState } from 'react'
import { Card, Grid, Typography } from '@material-ui/core'
import adminServices from '../utils/adminServices';

const Dashboard = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const res = await adminServices.getDashboard();
      if (res.status) {
        setData(res.data);
      } else {

      }
    }
    getData();
  }, []);

  console.log("DATA", data);

  return (
    <div >
      <Grid container>
        {
          data?.map((d) => {
            return <Grid item xs={3}>
              <Card style={{ padding: '5%', margin: '2%' }}>
                <Typography variant="h6" >{d.title}</Typography>
                <Typography variant="h4" >{d.count}</Typography>
              </Card>
            </Grid>
          })
        }
      </Grid>

    </div>
  )
}
export default Dashboard
