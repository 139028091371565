export const vendorSidebar = [
  {
    title: 'Dashboard',
    icon: 'fas fa-window-maximize',
    to: '/dashboard',
  },
  {
    title: 'Users',
    icon: 'fas fa-book',
    to: '/users',
  },
  {
    title: 'Vendors',
    icon: 'fas fa-database',
    to: '/vendors',
  },
  // {
  //   title: 'Profile',
  //   icon: 'fas fa-user',
  //   to: '/profile',
  // },
  {
    title: 'Configuration',
    icon: 'fas fa-layer-group',
    to: '/configuration',
  },
  {
    title: 'AdminUsers',
    icon: 'fas fa-layer-group',
    to: '/adminusers',
  },
  {
    title: 'Category',
    icon: 'fas fa-list',
    to: '/category',
  },
]
