import { Card } from '@material-ui/core'
import React from 'react'
import { useLocation } from 'react-router'

const UsersDetails = () => {
  let location = useLocation()
  let data = location.state
  return (
    <div>
      <Card>
        <h3 style={{ paddingLeft: '44px' }}>User Details</h3>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ width: '80%', paddingLeft: '45px' }}>
            <div className="usertable">
              <p className="usertable_style">Name</p>
              <p>:</p>
              <p className="userstable_row"> {data.name}</p>
            </div>
            <div className="usertable">
              <p className="usertable_style">Email</p>
              <p>:</p>
              <p className="userstable_row"> {data.email}</p>
            </div>
            <div className="usertable">
              <p className="usertable_style">Number</p>
              <p>:</p>
              <p className="userstable_row"> {data.mobile}</p>
            </div>
            <div className="usertable">
              <p className="usertable_style">Type</p>
              <p>:</p>
              <p className="userstable_row"> {data.type}</p>
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default UsersDetails
