import React, { useEffect, useState } from 'react'
import adminServices from '../../utils/adminServices'
import CustomTable from '../../component/CustomTable'
import { Button, Card, CircularProgress, Input, Typography } from '@material-ui/core'

const Configuration = () => {
  const [data, setData] = useState();

  useEffect(() => {
    async function getData() {
      const res = await adminServices.getConfig()
      if (res.status) {
        setData(res.data);
      } else {
        setData("");
      }
    }

    getData();
  }, [0]);

  const onEditConf = async () => {
    const res = await adminServices.editConfig();
  };

  const onUpdate = async () => {
    const res = await adminServices.updateConfig(data);
    if (res.status) {
      alert('updated successfully');
    } else {
      alert('Unable to update, please try again')
    }
  }

  const onChange = (key, value) => {
    // let d = JSON.parse(JSON.stringify(data));
    // d[key] = value;
    // console.log(key, value);
    // console.log(d);
    setData({ ...data, [key]: value });
  }

  const renderItem = (title, value) => {

    let keys = Object.keys(data);

    return keys.map((d, i) => {

      if (d == '_id' || d == 'createdAt' || d == '__v' || d == 'updatedAt') {
        return;
      }

      return <div style={{ marginLeft: '10%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', with: '100%', marginRight: '10%' }}>
        <Typography>{d}</Typography>

        <Input style={{ minWidth: '250px' }} onChange={(e) => onChange(d, e.target.value)} value={data[d]} ></Input>
      </div>
    })
  }

  return (
    <Card style={{ minHeight: "100vh", padding: "10px", width: "70%" }}>



      {
        data ? (
          <div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: "2%" }}>
              <Typography variant='h5' style={{ paddingLeft: '18px' }}>Configuration</Typography>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={() => onUpdate()}
                style={{ maxHeight: '40px', minWidth: '100px', marginTop: '10px' }}
              >
                Update
              </Button>

            </div>
            <div style={{ display: 'flex', flexDirection: 'row', }}>

              <div style={{ width: '100%', margin: "23px" }} >
                {
                  renderItem()
                }
              </div>
            </div>
          </div>
        ) : (
          <CircularProgress />
        )
      }
    </Card >
  );
};

export default Configuration;
