import React, { useState, useEffect } from 'react'
import adminServices from '../../utils/adminServices'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import { Button, Card, TextField } from '@material-ui/core'
import { useNavigate } from 'react-router'
import CustomTable from '../../component/CustomTable'




const Vendors = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState('')
  const navigate = useNavigate();







  useEffect(() => {
    async function getVendors() {
      setLoading(true)
      let res = await adminServices.getVendors()
      setLoading(false)
      if (res.status) {
        setData(res.data)
      } else {
        setData('')
      }
    }
    getVendors()
  }, [0])
  let columns = [
    {
      title: 'Uid',
      index: 'uid',
    },
    {
      title: 'Name',
      index: 'bussiness_name',
    },
    {
      title: 'Contact Number',
      index: 'bussiness_contact',
    },
    {
      title: 'Fashion Type',
      index: 'fashion_type',
    },
    {
      title: 'Service Type',
      index: 'service_type',
    },
    {
      title: 'Status',
      index: 'status',
    },
    {
      title: 'Action',
      index: 'status',
      variant: 'contained',

      render: (item) => {
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ margin: '10px 0px 0px 10px' }}
            onClick={() => navigate('/vendors/' + item._id, { state: item })}
          >
            View
          </Button>
        )
      },
    },
  ]

  return (
    <Card>
      <CustomTable
        title={'Vendor List'}
        loading={loading}
        columns={columns}
        data={data}

      />

    </Card>
  )
}

export default Vendors
