import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router";
import { Button, Card } from "@material-ui/core";
import CustomTable from "../../component/CustomTable";
import adminServices from "../../utils/adminServices";
import Tooltip from "@material-ui/core/Tooltip";

const VendorDetails = () => {
  let location = useLocation()
  let data = location.state
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState();
  let specialist = data.specialists
  let service = data.services
  const handler = async (value) => {
    const res = await adminServices.vendor({
      vendor_id: data._id,
      status: value,
    });
  };

  let columns = [
    {
      title: "Name",
      index: "name",
    },
    {
      title: "Email",
      index: "email_id",
    },
    {
      title: "NUMBER",
      index: "contact_no",
    },
    {
      title: "Service Type",
      index: "service_type",
    },
    {
      title: "Min Charges",
      index: "min_charges",
    },
    {
      title: "Descrption",
      index: "description",
    },
  ];
  let servicesData = [
    {
      title: "Name",
      index: "name",
    },
    {
      title: "Category",
      index: "category",
    },
    {
      title: "Price",
      index: "price",
    },
    {
      title: "Description",
      index: "description",
    },
  ];
  return (
    <Card style={{ minHeight: "100vh" }}>
      <div className="vender_heading_status">
        <h3 style={{ marginLeft: "40px" }}>Vendor Details</h3>
        <div style={{ display: "flex" }}>
          <div style={{ display: "flex" }}>
            <h4 style={{ paddingRight: "40px" }}>STATUS:{data.status}</h4>
           
              <Button
                style={{
                  marginRight: "30px",
                  marginTop: "20px",
                  height: "45%",
                }}
                variant="contained"
                color="primary"
                onClick={() =>
                  data.status == "active"
                    ? handler("inactive")
                    : handler("active")
                }
              >
                {data.status == "active" ? "Inactive" : "Active"}
              </Button>
           
          </div>
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ width: "80%", paddingLeft: "45px" }}>
          <div className="usertable">
            <p className="usertable_style">Id</p>
            <p>:</p>
            <p className="userstable_row">{data.uid}</p>
          </div>
          <div className="usertable">
            <p className="usertable_style">Name</p>
            <p>:</p>
            <p className="userstable_row"> {data.bussiness_name}</p>
          </div>
          <div className="usertable">
            <p className="usertable_style">Contact Number</p>
            <p>:</p>
            <p className="userstable_row"> {data.bussiness_contact}</p>
          </div>
          <div className="usertable">
            <p className="usertable_style">Fashion Type</p>
            <p>:</p>
            <p className="userstable_row"> {data.fashion_type}</p>
          </div>

          <div className="usertable">
            <p className="usertable_style">Service Type</p>
            <p>:</p>
            <p className="userstable_row">{data.service_type}</p>
          </div>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <p className="usertable_style">Address </p>
            <p style={{ marginRight: "10px" }}>:</p>
            <p className="usertable_address">
              {" "}
              {data.address.address_from_google}
            </p>
          </div>
        </div>
      </div>
      <div style={{ paddingLeft: "45px" }}></div>
      <div>
        <div style={{ width: "40%", paddingLeft: "20px", marginTop: "40px" }}>
          <h4 style={{ paddingLeft: 30 }}>Opening Hours:</h4>
          {data.opening_hours.map((day, i) => {
            return (
              <div className="details_list">
                <p>{day.name} </p>

                {day.hours.map((h, indx) => {
                  return (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {" "}
                      <p>
                        {h.hourFrom} - {h.hourTo}
                      </p>{" "}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>

      <div>
        <CustomTable
          title={"Services"}
          loading={loading}
          columns={servicesData}
          data={service}
        />
      </div>
      <div>
        <CustomTable
          title={"Specialist"}
          loading={loading}
          columns={columns}
          data={specialist}
        />
      </div>
    </Card>
  );
};
export default VendorDetails;
