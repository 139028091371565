import {  ACTION_LOGIN, ACTION_LOGOUT } from "./actions";

const initial = {
    loginStatus: false,
    user:{}
}

const store = (state = initial, action) => {
    switch (action.type) {
        case ACTION_LOGIN: {
            
            return {
                ...state,
                loginStatus: true,  
                user:action.data      
            }
          
        }

        case ACTION_LOGOUT:{
            return {
                ...state,
                loginStatus:false,  
               
            }
        }
        

        default: {
            return {
                ...state
            }
        }
    }
}

export default store;