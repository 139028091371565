import React from "react";
import { Route, Routes,Navigate } from "react-router-dom";
import Login from "../login/Login";
import Forgotpassword from "../login/Forgotpassword";
import Changepassword from "../login/Changepassword";


const LoginRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/forgot" element={<Forgotpassword />} />
      <Route path="/changepassword" element={<Changepassword />} exact />
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
};
export default LoginRoutes;
